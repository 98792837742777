import { useState } from 'react';

const SendCarriere = () => {
  const [selectedJob, setSelectedJob] = useState('');

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    resume: null,
    coverLetter: '',
  });

  const handleInputChange = (e) => {
    const { name, value, files } = e.target;
    setFormData({
      ...formData,
      [name]: files ? files[0] : value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    console.log('Form submitted:', formData);
  };
  return (
    <section className='bg-gray-100'>
      <div className=' py-10 w-[95%] md:w-[50%] mx-auto'>
        <h1 className='text-3xl font-bold text-center mb-8'>Join Our Team</h1>
        <div className=' bg-white shadow-md rounded-lg p-1 md:p-6'>
          <h2 className='text-2xl font-semibold mb-4'>Apply for job</h2>
          <form onSubmit={handleSubmit} className='grid grid-cols-1 gap-4'>
            <div>
              <label className='block mb-2 text-gray-700'>Name</label>
              <input
                type='text'
                name='name'
                value={formData.name}
                onChange={handleInputChange}
                required
                className='w-full px-3 py-2 border rounded'
              />
            </div>
            <div>
              <label className='block mb-2 text-gray-700'>Email</label>
              <input
                type='email'
                name='email'
                value={formData.email}
                onChange={handleInputChange}
                required
                className='w-full px-3 py-2 border rounded'
              />
            </div>
            <div>
              <label className='block mb-2 text-gray-700'>Resume</label>
              <input
                type='file'
                name='resume'
                onChange={handleInputChange}
                required
                className='w-full px-3 py-2 border rounded'
              />
            </div>
            <div>
              <label className='block mb-2 text-gray-700'>Cover Letter</label>
              <textarea
                rows={5}
                name='coverLetter'
                value={formData.coverLetter}
                onChange={handleInputChange}
                required
                className=' resize-none  w-full px-3 py-2 border rounded'
              />
            </div>
            <div>
              <button
                type='submit'
                className='bg-[#00075e] text-white py-2 px-4 rounded hover:bg-green-700'
              >
                Submit Application
              </button>
            </div>
          </form>
        </div>
      </div>
    </section>
  );
};

export default SendCarriere;
