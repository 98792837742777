import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import { getStorage } from 'firebase/storage';

const firebaseConfig = {
  apiKey: 'AIzaSyB8d90FC9aY_A0hXDVDrdaB7N2UBq6pHtg',
  authDomain: 'keindeya-admin.firebaseapp.com',
  projectId: 'keindeya-admin',
  storageBucket: 'keindeya-admin.appspot.com',
  messagingSenderId: '881913199863',
  appId: '1:881913199863:web:208573644d38bd223ea7f2',
  measurementId: 'G-CZZJV1LCGL',
};

// inti firebase

const app = initializeApp(firebaseConfig);

const db = getFirestore(app);

const auth = getAuth(app);
const storage = getStorage(app);

export { db, auth, storage };
